import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Logout() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') || undefined;

  useEffect(() => {
    localStorage.clear();

    if (redirectUrl) {
      navigate(`/?redirectUrl=${redirectUrl}`);
    } else {
      navigate('/');
    }
  }, [navigate, redirectUrl]);

  return null;
}
