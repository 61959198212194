import React, { useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PencilSimple, ArrowLeft } from 'phosphor-react';
import { actions as authActions } from '../../redux/modules/auth.js';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import myTheme from '../../styles/theme';

import { Container, Title, Items, Item, ButtonsArea, ModalArea } from './styles';

import UpdateProfile from '../UpdateProfile';

import config from '../../config.js';

export default function Roles() {
  const [selectedRole, setSelectedRole] = useState('simulatoro');
  const [modalUpdate, setModalUpdate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const legacyToken = localStorage.getItem('legacy_token');
  const [searchParams] = useSearchParams();
  const [roleChange, setRoleChange] = useState(searchParams.get('roleChange') || undefined);
  const roleChangeAction = searchParams.get('roleChangeAction') || undefined;

  useEffect(() => {
    dispatch(authActions.roles());
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    } else {
      dispatch(authActions.logged(token));
    }
  }, [navigate, token, dispatch]);

  const handleToLogin = () => {
    navigate('/logout');
  };

  const closeUpdateDataModal = () => {
    setModalUpdate(false);
  };

  const openUpdateDataModal = () => {
    setRoleChange(undefined);
    setModalUpdate(true);
  };

  return (
    <Layout title='Roles - IDM' reverse={false}>
      <Container>
        <Title>Escolha uma opção:</Title>
        <Items>
          <Item
            active={selectedRole === 'simulatoro'}
            onClick={() => setSelectedRole('simulatoro')}
            as='a'
            href={`${config.LINK_SIMULATORO}?token=${token}${
              legacyToken && legacyToken !== 'undefined' ? `&legacy_token=${legacyToken}` : ''
            }`}
          >
            <PencilSimple size={30} weight='thin' />
            Fazer simulado
          </Item>
        </Items>
        <ButtonsArea>
          <Button variant='two' onClick={handleToLogin}>
            <ArrowLeft size={18} />
            Voltar para login
          </Button>
          <Button variant='two' onClick={openUpdateDataModal}>
            Editar seus dados
          </Button>
        </ButtonsArea>
        <ModalArea
          isOpen={modalUpdate}
          onRequestClose={closeUpdateDataModal}
          contentLabel='Modal Update Data Profile'
          ariaHideApp={false}
          style={{
            overlay: {
              position: myTheme.media.mobile ? 'absolute' : 'fixed',
            },
          }}
        >
          <UpdateProfile
            closeModal={closeUpdateDataModal}
            modalUpdate={modalUpdate}
            roleChange={roleChange}
            roleChangeAction={roleChangeAction}
          />
        </ModalArea>
      </Container>
    </Layout>
  );
}
